import '@vkontakte/vkui/dist/vkui.css';
import './App.css';
import React,{useEffect, useState, Suspense} from 'react';
import {
    AdaptivityProvider,
    AppRoot,
    ConfigProvider,
    SplitLayout,
    View, Alert, ScreenSpinner
} from "@vkontakte/vkui";
import ProfilePanel from "./ProfilePanel/ProfilePanel";
import {useDispatch, useSelector} from "react-redux";
import {loginUser, updateUserPrefs} from "./Redux/authReducer";
import {initialisedSuccess, setActivePanel, setOnlineStatus, setSign, toggleShowGuide, updateMiniSettings, wsMessageHandler, initSdk, authYa} from './Redux/appReducer';
import InitPanel from "./InitPanel/InitPanel";
import Modal from "./Modal/Modal";
import QuestionPanel from "./QuestionPanel/QuestionPanel";
import AskPanel from "./AskPanel/AskPanel";
import UserStatsPanel from "./UserStatsPanel/UserStatsPanel";

import useWebSocket,{ ReadyState } from "react-use-websocket";
import {checkDev} from "./utils/helpers";
import NotifyPanel from "./NotifyPanel/NotifyPanel";
import ChatsPanel from "./ChatsPanel/ChatsPanel";
import ChatPanel from "./ChatPanel/ChatPanel";
import {loadRandomQuest} from "./Redux/questionReducer";
import SettingsPanel from "./SettingsPanel/SettingsPanel";
import BannedDeletedPanel from "./BannedDeletedPanel/BannedDeletedPanel";
import {getRouterHash, historyPush, loadHash, toggleDoNotAdd} from "./Redux/routerReducer";
import RewardsPanel from "./RewardsPanel/RewardsPanel";
import LogsPanel from "./LogsPanel/LogsPanel";
import LeadersPanel from "./LeadersPanel/LeadersPanel";
import RatePanel from "./RatePanel/RatePanel";
import GiftPanel from "./GiftPanel/GiftPanel";
import StaticPanel from "./StaticPanel/StaticPanel";
import ErrorPanel from './ErrorPanel/ErrorPanel';
const Guide = React.lazy(() => import("./Guide/Guide"));
//import Guide from './Guide/Guide';



function App() {

    /*
    Для роутера
    const hash = window.location.hash.split('/')
    console.log(hash[1],hash[2])
    https://vk.com/app7301497#/q/id
    https://vk.com/app7301497#/a/id
    https://vk.com/app7301497#/@/username
    https://vk.com/app7301497#/pm/username
     */

    const dispatch = useDispatch();
    const app = useSelector(state => state.app);
    const auth = useSelector(state => state.auth);
    const [popout, setPopout] = useState(null);

    const doNotAdd = useSelector(state => state.router.doNotAdd);
    const hash = useSelector(state => state.router.hash);



    const {
        sendMessage,
        lastMessage,
        readyState,
    } = useWebSocket(checkDev(`/ws/init${app.cookiesSupport===false?`?token=${localStorage.getItem('access_token')}`:''}`,true), {
        shouldReconnect: async (closeEvent) =>{
            if(closeEvent.code === 4001){
                //let status = await dispatch(refreshTokens())
                return false // status === 0;
            }
            else {
                return true
            }
        },
        retryOnError: true,
        }, app.onlineReady
    );


    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    const init_ya_sdk = async () => {
        window.ysdk = await window.YaGames.init({screen:{orientation:'landscape',lock:true}})
        //const player = await window.ysdk.getPlayer({ signed: true,scopes: true })
        //console.log('DEBUG!!!',player.getMode())
        //return player.signature
      }

    const get_player_info = async ()=> { 
        const _player = await window.ysdk.getPlayer({ signed: true,scopes: true })
        const player = {is_authenticated: _player.getMode() !== 'lite', sign:_player.signature}
        dispatch(authYa(player.is_authenticated))
        if(player.is_authenticated) dispatch(setSign(player.sign))
        return player
    }




    useEffect(()=>{
        if(readyState === ReadyState.OPEN) sendMessage('whoAmI')
        dispatch(setOnlineStatus(readyState === ReadyState.OPEN))
    },[readyState]);

    useEffect(()=>{
        if(lastMessage) dispatch(wsMessageHandler(JSON.parse(lastMessage.data)))
    },[lastMessage]);

    //инициализация приложение
    useEffect(()=>{
        init_ya_sdk().then((sign)=>{
            dispatch(initSdk())
        })
    },[]);

    useEffect(()=>{
        if(app.yasdkInitialized){
            get_player_info()
            dispatch(getRouterHash(window.location.hash))
        }
    },[app.yasdkInitialized]);

    useEffect(()=>{
        if(app.isYaAuthenticated===false){
            window.ysdk.auth.openAuthDialog()
            window.addEventListener('focus',get_player_info)
        }
        else window.removeEventListener('focus',get_player_info)
    },[app.isYaAuthenticated]);

    //после получения адреса, запрашиваем токены с сервера, если приложение у пользователя установлено.
    useEffect(()=>{
        if(app.sign) {
            dispatch(loginUser(app.sign))
            window.addEventListener('focus',()=>{
                sendMessage('whoAmI')
            })
        }
    },[app.sign]);




    //Ставим статус "инициализировано", если приложение онлайн, пользователь авторизовался и инициализации не было до этого
    useEffect(() => {
        if(!app.initialised&&auth.is_authenticated &&app.online
            ) {
            dispatch(initialisedSuccess());
        }

    }, [auth.is_authenticated, app.online
    ])

    //Загружаем стартовую страницу
    useEffect(()=>{
        if(app.initialised){
            if([101,103].includes(auth.userStatus)) dispatch(setActivePanel('bannedDeleted'))
            else if(hash.panel) dispatch(loadHash(hash))
            else {
                dispatch(setActivePanel('question'));
                dispatch(loadRandomQuest())
            }
            //dispatch(getUserPrefs(window.location.href))
        }
    },[app.initialised,auth.userStatus]);

    //router
    useEffect(()=>{

        if(app.activePanel&&!doNotAdd && !["init","question","stats","chat","bannedDeleted"].includes(app.activePanel)){
            dispatch(historyPush({
                panel: app.activePanel,
                type:null,
                id: null
            }))
        }
        dispatch(toggleDoNotAdd(false))
    },[app.activePanel]);

    const getTokenError = () =>{
        try{
            return localStorage.getItem('token_error');
           }
        catch(e) {return null}
    }
    
    //отслеживаем ошибку обновления токена
    useEffect(()=>{
        if(getTokenError()){
            localStorage.removeItem('token_error')
            console.error('Token Refresh Error')
            dispatch(setActivePanel('error'))
        }
    },[getTokenError()]);

    //Авто подсказки. Статус сохраняется на стороне сервера

    useEffect(()=>{
        if(auth.prefs.guide_viewed===false&&app.activePanel==='question'){
            setTimeout(() => {
                dispatch(toggleShowGuide(true));
                dispatch(updateUserPrefs(window.location.href,'guide',1))
            }, 3000);
        }
    },[auth.prefs.guide_viewed]);

  return (
      <ConfigProvider scheme={app.scheme}>
        <AdaptivityProvider>
          <AppRoot
              noLegacyClasses
          >
              <SplitLayout >
                  <View activePanel={app.activePanel} popout={popout} modal={<Modal id={'modal'} activeModal={app.activeModal} setPopout={setPopout}/>}>
                      <ProfilePanel
                          id={"profile"} setPopout={setPopout}/>
                      <InitPanel
                          id={"init"}/>
                      <QuestionPanel 
                      id={"question"} setPopout={setPopout}/>
                      <UserStatsPanel
                          id={"stats"} setPopout={setPopout}
                      />
                      <AskPanel
                          id={"ask"}
                      />
                      <NotifyPanel
                          id={"notify"}
                      />
                      <ChatsPanel
                          id={"chats"}
                      />
                      <ChatPanel
                          id={"chat"}
                      />
                      <SettingsPanel
                          id={"settings"}
                          setPopout={setPopout}
                      />
                      <BannedDeletedPanel
                          id={"bannedDeleted"}
                      />
                      <RewardsPanel
                          id={"rewards"}
                      />
                      <LogsPanel
                          id={"logs"}
                      />
                      <LeadersPanel
                          id={"leaders"}
                      />
                      <RatePanel
                          id={"rate"}
                          setPopout={setPopout}
                      />
                      <GiftPanel
                          id={"gifts"}
                          setPopout={setPopout}
                      />
                      <StaticPanel
                          id={"static"}
                      />
                      <ErrorPanel
                          id={"error"}
                      />
                  </View>
              </SplitLayout>
              {app.showGuide&&<Suspense 
              fallback={<ScreenSpinner/>}><Guide/></Suspense>}
          </AppRoot>
        </AdaptivityProvider>
      </ConfigProvider>
  );
}

export default App;
