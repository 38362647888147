import {setAuth, setSettings} from "./authReducer";
import {setNotifs} from "./notifyReducer";
import {setBroadcast} from "./headerReducer";
import otvechaiMiniApi from "../api/otvechaiMiniApi";


const queryString = require('query-string');

const SET_VK_ERROR = 'SET_VK_ERROR'
const SET_SIGN = 'SET_SIGN'
const SET_APP_INSTALLED = 'SET_APP_INSTALLED'
const SET_BACKEND_ERROR = 'SET_BACKEND_ERROR'
const CLEAR_ERRORS = 'CLEAR_ERRORS'

const INIT_APP = 'INIT_APP';
const ONLINE_APP = 'ONLINE_APP';
const ONLINE_READY = 'ONLINE_READY';
const SET_BACK_VERSION = 'SET_BACK_VERSION';
const SET_SCHEME = 'SET_SCHEME';

const TOGGLE_IS_FETCHING = 'TOGGLE_IS_FETCHING';
const TOGGLE_IN_PROGRESS = 'TOGGLE_IN_PROGRESS';
const TOGGLE_HAS_MORE = 'TOGGLE_HAS_MORE';
const TOGGLE_MORE_LOADING = 'TOGGLE_MORE_LOADING';

const SET_ACTIVE_PANEL = 'SET_ACTIVE_PANEL';
const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL';

const SET_COOKIES_SUPPORT = 'SET_COOKIES_SUPPORT';

const UPDATE_APP_SETTINGS = 'UPDATE_APP_SETTINGS';
const TOGGLE_SHOW_GUIDE = 'TOGGLE_SHOW_GUIDE';
const SET_CLIENT = 'SET_CLIENT';

const FETCH_COUNT = 'FETCH_COUNT';

const INIT_SDK = 'INIT_SDK';
const AUTH_YA = 'AUTH_YA';



let initialState = {
    initialised:null,
    online:null,
    onlineReady:null,
    isFetching: null,
    fetchCount: 0,
    inProgress:null,
    hasMore:null,
    moreLoading:null,
    frontCurrent:0.01,
    backVersion:null,
    activePanel:'init',
    activeModal:null,
    scheme: window.matchMedia('(prefers-color-scheme: dark)')?'space_gray':'bright_light',
    client:{platform:null,version:null},
    cookiesSupport:null,
    debug:false,
    showGuide:false,
    yasdkInitialized:false,
    isYaAuthenticated:null,
    sign:null,
    error:{
        vk:null,
        backend:null
    },


}

export const toggleIsFetching = (isFetching) => ({type:TOGGLE_IS_FETCHING, isFetching});
export const toggleInProgress = (status) => ({type:TOGGLE_IN_PROGRESS, status});
export const toggleHasMore = (status) => ({type:TOGGLE_HAS_MORE, status});
export const toggleMoreLoading = (status) => ({type:TOGGLE_MORE_LOADING, status});
export const setSign = (sign) => ({type:SET_SIGN, sign});
export const setAppInstalled = (status) => ({type:SET_APP_INSTALLED, status})
export const setVkError = (code) => ({type:SET_VK_ERROR,code})
export const setBackendError = (code) => ({type:SET_BACKEND_ERROR,code})
export const clearErrors = () => ({type:CLEAR_ERRORS})

export const setOnlineStatus = (status) => ({type:ONLINE_APP,status});
export const setOnlineReady = (status) => ({type:ONLINE_READY,status});
export const setBackVersion =(version) => ({type:SET_BACK_VERSION,version});
export const initialisedSuccess = () => ({type:INIT_APP});

export const setScheme = (scheme) => ({type:SET_SCHEME,scheme});

export const setActivePanel =(panel) => ({type:SET_ACTIVE_PANEL,panel});
export const setActiveModal =(modal) => ({type:SET_ACTIVE_MODAL,modal});

export const setCookiesSupport = (support) => ({type:SET_COOKIES_SUPPORT,support})

export const updateAppSettings = (settings) => ({type:UPDATE_APP_SETTINGS,settings})

export const toggleShowGuide = (status) => ({type:TOGGLE_SHOW_GUIDE,status})

export const setClient = (client) => ({type:SET_CLIENT,client})

export const fetchCount = () => ({type:FETCH_COUNT})

export const initSdk = () => ({type:INIT_SDK})
export const authYa = (auth) => ({type:AUTH_YA,auth})

export const wsMessageHandler = (data) => async (dispatch) => {
    if(data.type==='whoAmI') {
        dispatch(setAuth(data.data.whoAmI))
        dispatch(setNotifs(data.data.whoAmI.notifs))
        dispatch(setBroadcast(data.data.broadcast))
        dispatch(setSettings(data.data.whoAmI.settings))
        dispatch(setBackVersion(data.data.whoAmI.version))
    }
    else if (data.type==='notifications'){
        dispatch(setNotifs(data.data))
    }
    else if (data.type==='broadcast'){
        dispatch(setBroadcast(data.data))
    }
}

export const updateMiniSettings = (url,settings) => {
    return async (dispatch) => {
        try{
            const response = await otvechaiMiniApi.changeAppSettings({url,settings})
            if(response.data.status===0) dispatch(updateAppSettings(response.data.settings))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleIsFetching(false))
        }


    }
}


const appReducer  = (state = initialState, action) => {
    switch (action.type) {
        case SET_SIGN:
            return {
                ...state,
                sign: action.sign
            };
        case UPDATE_APP_SETTINGS:
            return {
                ...state,
                info:{
                    ...state.info,
                    installed:action.settings.installed,
                    notifications:action.settings.notifications,
                    favorite:action.settings.favorite
                }
            };
        case SET_APP_INSTALLED:
            return {
                ...state,
                info: {
                    ...state.info,installed:action.status
                }
            };
        case SET_SCHEME:
            return {
                ...state,
                scheme: action.scheme
            };
        case SET_CLIENT:
            return {
                ...state,
                client: action.client
            };
        case SET_VK_ERROR:
            return {
                ...state,
                error:{...state.error,vk: action.code}
            };
        case SET_BACKEND_ERROR:
            return {
                ...state,
                error:{...state.error,backend: action.code}
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                error:{vk:null,backend:null}
            };
        case INIT_APP:
            return{
                ...state,
                initialised: true
            };
        case ONLINE_APP:
            return{
                ...state,
                online: action.status
            };
        case ONLINE_READY:
            return{
                ...state,
                onlineReady: action.status
            };
        case SET_BACK_VERSION:
            return{
                ...state,
                backVersion: action.version
            };
        case TOGGLE_IS_FETCHING:
            return{
                ...state,
                isFetching: action.isFetching
            };
        case TOGGLE_IN_PROGRESS:
            return{
                ...state,
                inProgress: action.status
            };
        case TOGGLE_HAS_MORE:
            return{
                ...state,
                hasMore: action.status
            };
        case TOGGLE_MORE_LOADING:
            return{
                ...state,
                moreLoading: action.status
            };
        case SET_ACTIVE_PANEL:
            return{
                ...state,
                activePanel: action.panel
            };
        case SET_ACTIVE_MODAL:
            return{
                ...state,
                activeModal: action.modal
            };
        case SET_COOKIES_SUPPORT:
            return{
                ...state,
                cookiesSupport: action.support
            };
        case TOGGLE_SHOW_GUIDE:
            return{
                ...state,
                showGuide:action.status
            };
        case FETCH_COUNT:
            return{
                ...state,
                fetchCount:state.fetchCount + 1
            };
        
        case INIT_SDK:
            return{
                ...state,
                yasdkInitialized:true
            };
        case AUTH_YA:
            return{
                ...state,
                isYaAuthenticated:action.auth
            }

        default:
            return state
    }
};

export default appReducer