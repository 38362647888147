import otvechaiApi from "../api/otvechaiMiniApi";
import {setBackendError, setCookiesSupport, setOnlineReady, setVkError} from "./appReducer";
import {setNotifs} from "./notifyReducer";
import {enableAllNotifs} from "./settingsReducer";

const TOGGLE_AUTH_IN_PROGRESS = 'TOGGLE_AUTH_IN_PROGRESS'
const SET_VK_TOKEN = 'SET_VK_TOKEN';

const SET_AUTH = 'SET_AUTH';
const TOGGLE_NEED_REGISTER = 'TOGGLE_NEED_REGISTER'
const SET_USER_STATUS = 'SET_USER_STATUS';
const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
const SET_SETTINGS = 'SET_SETTINGS';
const SET_USER_EMAIL = 'SET_USER_EMAIL';
const SET_USER_EMAIL_ERROR = 'SET_USER_EMAIL_ERROR';
const SET_USER_PREFS = 'SET_USER_PREFS';

let initialState = {
    is_authenticated: null,
    need_register:null,
    authInProgress:null,
    userStatus:null,
    banReason:null,
    new_email:null,
    new_email_error:{
        id: null,
        username: null,
        avatar_small: null,
        name:null,
        email:null
        },
    user: {
        id: null,
        username: null,
        avatar_small: null
    },
    settings:{},
    prefs:{
        guide_viewed:null,
        email_requested:null
    },
    token: {
        vk_token: null,
    }
}

const setVkToken = (token) => ({type:SET_VK_TOKEN, token});

export const toggleAuthInProgress = (auth) => ({type:TOGGLE_AUTH_IN_PROGRESS, auth});

export const setAuth = (auth) => ({type:SET_AUTH, auth});
export const toggleNeedRegister = (status) => ({type:TOGGLE_NEED_REGISTER, status});
export const setUserStatus = (userStatus) => ({type:SET_USER_STATUS, userStatus});
export const setSettings = (settings) => ({type:SET_SETTINGS, settings});
const setUserEmail = (email) => ({type:SET_USER_EMAIL,email})
const setUserEmailError = (user) => ({type:SET_USER_EMAIL_ERROR,user})
const setUserPrefs = (prefs) => ({type:SET_USER_PREFS,prefs})

export const refreshTokens = () => {
    return async (dispatch) => {
        try {
            const response = await otvechaiApi.refresh()
            if (!response.data.cookies&&response.data.tokens){
                localStorage.setItem('access_token',response.data.tokens.access_token)
                localStorage.setItem('refresh_token',response.data.tokens.refresh_token)
            }
            return response.data.status
        }
        catch (error) {
            dispatch(setVkError(error))
            return 1
        }


    }
}


export const getUserPrefs = (url) => {
    return async (dispatch) => {
        try{
            const response = await otvechaiApi.getPrefs({url})
            if(response.status===200&&response.data.status===0) {
                dispatch(setUserPrefs(response.data.mini_prefs))
            }
        }
        catch (error){
            console.log(error)
        }
    }
}

export const updateUserPrefs = (url, pref, val) => {
    return async (dispatch) => {
        try{
            const response = await otvechaiApi.updatePrefs({url,[pref]:val})
            if(response.status===200&&response.data.status===0) {
                dispatch(setUserPrefs(response.data.mini_prefs))
            }
        }
        catch (error){
            console.log(error)
        }
    }
}

export const registerUser = (url,info) => {
    return async (dispatch) => {
        try{
            const response = await otvechaiApi.register(url,info)
            if(response.status===200&&response.data.status===0) {
                dispatch(setAuth(response.data.auth))
                dispatch(setNotifs(response.data.auth.notifs))
                dispatch(toggleNeedRegister(false))
                dispatch(setCookiesSupport(response.data.cookies))
                if (!response.data.cookies&&response.data.tokens){
                    localStorage.setItem('access_token',response.data.tokens.access_token)
                    localStorage.setItem('refresh_token',response.data.tokens.refresh_token)
                }
                dispatch(setOnlineReady(true))
            }
        }
        catch (error){
            dispatch(setBackendError(1))
        }
    }
}

export const updateUserAvatar = (avatar) => ({type:UPDATE_USER_AVATAR, avatar});

export const loginUser = (sign) => {
    return async (dispatch) => {
        try {
            const response = await otvechaiApi.login(sign)
            if (response.status === 200)
                if(response.data.status===0) {
                    dispatch(setAuth(response.data.auth))
                    dispatch(setNotifs(response.data.auth.notifs))
                    dispatch(setCookiesSupport(response.data.cookies))
                    if (!response.data.cookies&&response.data.tokens){
                        localStorage.setItem('access_token',response.data.tokens.access_token)
                        localStorage.setItem('refresh_token',response.data.tokens.refresh_token)
                    }
                    dispatch(setOnlineReady(true))
                }
                else dispatch(toggleNeedRegister(true))
            else if(response.data.status) dispatch(setBackendError(response.data.status))
        }
        catch (error){
            dispatch(setBackendError(1))
        }

    }
}

export const setEmail = (obj) => {
    return async (dispatch) => {
        try {
            const response = await otvechaiApi.setEmail(obj)
            if(response.data.status===0) {
                dispatch(setUserEmail(response.data.email))
                dispatch(enableAllNotifs())
            }
            else if (response.data.status===2)
                dispatch(setUserEmailError(response.data.user))
            return(response.data.status)
        }
        catch (error) {
            dispatch(setVkError(error))
            return({status:1})
        }


    }
}


const authReducer  = (state = initialState, action) => {
    switch (action.type) {
        case SET_VK_TOKEN:
            return {
                ...state,
                token:{...state.token,vk_token: action.token}
            };
        case TOGGLE_AUTH_IN_PROGRESS:
            return {
                ...state,
                authInProgress:action.auth
            };
        case TOGGLE_NEED_REGISTER:
            return {
                ...state,
                need_register: action.status,
            };
        case SET_AUTH:
            return {
                ...state,
                is_authenticated: action.auth.is_authenticated,
                user:action.auth.user,
                userStatus:action.auth.userStatus,
                banReason:action.auth.banReason,
                mod:action.auth.mod
            };
        case SET_USER_STATUS:
            return {
                ...state,
                userStatus:action.userStatus
            };
        case UPDATE_USER_AVATAR:
            return {
                ...state,
                user:{...state.user,avatar_small:action.avatar}
            };
        case SET_USER_EMAIL:
            return {
                ...state,
                new_email:action.email
            };
        case SET_USER_EMAIL_ERROR:
            return {
                ...state,
                new_email_error:action.user
            };
        case SET_USER_PREFS:
            return {
                ...state,
                prefs:action.prefs
            };
        case SET_SETTINGS:
            return {
                ...state,
                settings: {
                    enable_mobile_emoji:action.settings.enable_mobile_emoji,
                    disable_enter:action.settings.disable_enter,
                    disable_sound:action.settings.disable_sound,
                    stretched:action.settings.stretched
                }
            };
    }
    return state
};

export default authReducer

