import otvechaiMiniApi from '../api/otvechaiMiniApi'
import {
    setBackendError,
    toggleHasMore,
    toggleIsFetching,
    toggleMoreLoading,
} from "./appReducer";

let initialState = {
    username:null,
    sub:null,
    type:null,
    fromNotes:null,
    myStats:null,
    postInProgress:null,
    status:null,
    random:null,
    name:null,
    quests:[],
    answers:[],
    comments:[]
}

const SET_USER_USERNAME = "SET_USER_USERNAME";
const SET_USER_NAME = "SET_USER_NAME";
const SET_USER_QUESTS = "SET_USER_QUESTS";
const SET_USER_ANSWERS = "SET_USER_ANSWERS";
const SET_USER_COMMENTS = "SET_USER_COMMENTS";
const ADD_USER_QUESTS = "ADD_USER_QUESTS";
const ADD_USER_ANSWERS = "ADD_USER_ANSWERS";
const ADD_USER_COMMENTS = "ADD_USER_COMMENTS";
const TOGGLE_USER_QUESTS_LIKE = 'TOGGLE_USER_QUESTS_LIKE';
const SET_USER_QUESTS_LIKED = "SET_USER_QUESTS_LIKED";
const SET_USER_QUESTS_LIKES = "SET_USER_QUESTS_LIKES";
const TOGGLE_USER_ANSWERS_LIKE = 'TOGGLE_USER_ANSWERS_LIKE';
const SET_USER_ANSWERS_LIKED = "SET_USER_ANSWERS_LIKED";
const SET_USER_ANSWERS_LIKES = "SET_USER_ANSWERS_LIKES";
const TOGGLE_USER_COMMENTS_LIKE = 'TOGGLE_USER_COMMENTS_LIKE';
const SET_USER_COMMENTS_LIKED = "SET_USER_COMMENTS_LIKED";
const SET_USER_COMMENTS_LIKES = "SET_USER_COMMENTS_LIKES";
const TOGGLE_USER_POST_SUBSCRIPTION = 'TOGGLE_USER_POST_SUBSCRIPTION'

const SET_USER_QUEST_REMOVED = "SET_USER_QUEST_REMOVED";
const SET_USER_ANSWER_REMOVED = "SET_USER_ANSWER_REMOVED";
const SET_USER_COMMENT_REMOVED = "SET_USER_COMMENT_REMOVED";

const TOGGLE_USER_QUEST_STOPPED = 'TOGGLE_USER_QUEST_STOPPED'

const SET_STATS_TYPE = "SET_STATS_TYPE";

const TOGGLE_STATS_FROM_NOTES = "TOGGLE_STATS_FROM_NOTES"

const SET_SUB_STAT_TYPE = "SET_SUB_STAT_TYPE"

const LOAD_MY_STATS = "LOAD_MY_STATS"


export const setUserName = (name) => ({type:SET_USER_USERNAME,name});
export const setStatsType = (statstype) => ({type:SET_STATS_TYPE,statstype});
export const setName = (name) => ({type:SET_USER_NAME,name});
export const setUserQuests = (quests) => ({type:SET_USER_QUESTS, quests});
export const setUserAnswers = (answers) => ({type:SET_USER_ANSWERS, answers});
export const setUserComments = (comments) => ({type:SET_USER_COMMENTS, comments});
export const addQuests = (quests) => ({type:ADD_USER_QUESTS, quests});
export const addAnswers = (answers) => ({type:ADD_USER_ANSWERS, answers});
export const addComments = (comments) => ({type:ADD_USER_COMMENTS, comments});
export const setQuestsLiked = (id,liked,stat) => ({type:SET_USER_QUESTS_LIKED, id, liked,stat});
export const setQuestsLikes = (id, likes,stat) => ({type:SET_USER_QUESTS_LIKES, id, likes,stat});
export const toggleQuestsLike = (id,stat) => ({type:TOGGLE_USER_QUESTS_LIKE,id,stat});
export const setAnswersLiked = (id,liked,stat) => ({type:SET_USER_ANSWERS_LIKED, id, liked,stat});
export const setAnswersLikes = (id, likes,stat) => ({type:SET_USER_ANSWERS_LIKES, id, likes,stat});
export const toggleAnswersLike = (id,stat) => ({type:TOGGLE_USER_ANSWERS_LIKE,id,stat});
export const setCommentsLiked = (id,liked,stat) => ({type:SET_USER_COMMENTS_LIKED, id, liked,stat});
export const setCommentsLikes = (id, likes,stat) => ({type:SET_USER_COMMENTS_LIKES, id, likes,stat});
export const toggleCommentsLike = (id,stat) => ({type:TOGGLE_USER_COMMENTS_LIKE,id,stat});
const toggleUserPostSubscription = (id,sub,objType,statType) => ({type:TOGGLE_USER_POST_SUBSCRIPTION, id,sub,objType,statType});

export const toggleStatsFromNotes = (status) => ({type:TOGGLE_STATS_FROM_NOTES,status})
export const toggleLoadMyStats = (status) => ({type:LOAD_MY_STATS,status})

const setUserQuestRemoved = (id)=>({type:SET_USER_QUEST_REMOVED,id})
const setUserAnswerRemoved = (id)=>({type:SET_USER_ANSWER_REMOVED,id})
const setUserCommentRemoved = (id)=>({type:SET_USER_COMMENT_REMOVED,id})

const toggleUserQuestStopped = (id,stopped) => ({type:TOGGLE_USER_QUEST_STOPPED,id,stopped})

export const setSubStatType = (status) => ({type:SET_SUB_STAT_TYPE,status})

export const getUserStats = (username,stats,my=null,page=null) => {
    return async (dispatch) => {
        dispatch(toggleIsFetching(true));
        try{
            let response = await otvechaiMiniApi.getUserStats(username,stats,my,page);
            if(response.status===200) {
                (stats==='quests'||stats==='top')&&dispatch(setUserQuests(response.data['quests']));
                (stats==='answers'||stats==='stream')&&dispatch(setUserAnswers(response.data['answers']));
                stats==='comments'&&dispatch(setUserComments(response.data[stats]));
                dispatch(setName(response.data.user_name));
                dispatch(setSubStatType(my))
                dispatch(toggleHasMore(response.data.hasMore));
                dispatch(toggleLoadMyStats(false))
            }
            dispatch(toggleIsFetching(false));
            dispatch(toggleLoadMyStats(false))
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleMoreLoading(false));
        }
    }
}

export const getMoreStats = (username,stats,my,page) => {
    return async (dispatch) => {
        dispatch(toggleMoreLoading(true))
        try {
            let response = await otvechaiMiniApi.getUserStats(username,stats,my,page);
            if(response.status===200) {
                stats==='quests'&&dispatch(addQuests(response.data[stats]));
                stats==='answers'&&dispatch(addAnswers(response.data[stats]));
                stats==='comments'&&dispatch(addComments(response.data[stats]));
                dispatch(toggleHasMore(response.data.hasMore));
            }
            dispatch(toggleMoreLoading(false));

        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
            dispatch(toggleMoreLoading(false));
        }
    }
}

export const likeQuests = (id,stat) => {
    return async (dispatch) => {
        dispatch(toggleQuestsLike(id,stat))
        let response = await otvechaiMiniApi.likeQuestion(id);
        if(response.data.status===0) {
            dispatch(setQuestsLiked(id,response.data.liked,stat))
            dispatch(setQuestsLikes(id,response.data.likes,stat))
        }
        else{
            dispatch(toggleQuestsLike(id))
        }
    }
}

export const stopQuests = (id,action) => {
    return async (dispatch) => {
        try {
            let response = await otvechaiMiniApi.stopQuestion(id, action);
            if (response.status === 200) {
                dispatch(toggleUserQuestStopped(response.data.id, response.data.stopped))
            }
        }
        catch (e) {
            if(e.response) dispatch(setBackendError(e.response.status))
            else dispatch(setBackendError(1))
        }

    }
}

export const likeAnswers = (id,stat) => {
    return async (dispatch) => {
        dispatch(toggleAnswersLike(id,stat))
        let response = await otvechaiMiniApi.likeAnswer(id);
        if(response.data.status===0) {
            dispatch(setAnswersLiked(id,response.data.liked,stat));
            dispatch(setAnswersLikes(id,response.data.likes,stat));
        }
        else{
            dispatch(toggleAnswersLike(id))
        }
    }
}

export const likeComments = (id,stat) => {
    return async (dispatch) => {
        dispatch(toggleCommentsLike(id,stat))
        let response = await otvechaiMiniApi.likeComment(id);
        if(response.data.status===0) {
            dispatch(setCommentsLiked(id,response.data.liked,stat));
            dispatch(setCommentsLikes(id,response.data.likes,stat));
        }
        else{
            dispatch(toggleCommentsLike(id))
        }
    }
}

export const sendStatsSub = (id,type,statType) =>{
    return async (dispatch) =>{
        let response = await otvechaiMiniApi.subPost(id,type)
        if(response.data.status===0) {
            dispatch(toggleUserPostSubscription(id,response.data.subscribed,type,statType))
        }
    }
}

export const removeUserPost = (id,type,statType,topId=null) =>{
    return async (dispatch) =>{
        let response = await otvechaiMiniApi.removePost(id,type)
        if(response.data.status===0){
            if(statType==='userQuests') dispatch(setUserQuestRemoved(topId))
            else if(statType==='userAnswers') dispatch(setUserAnswerRemoved(topId))
            else if(statType==='userComments') dispatch(setUserCommentRemoved(topId))
        }
        else{
            console.log('error')
        }
    }
}

const userPostReducer = (state=initialState,action) => {
    switch (action.type) {
        case TOGGLE_STATS_FROM_NOTES:
            return{
                ...state,
                fromNotes: action.status
            };
        case LOAD_MY_STATS:
            return{
                ...state,
                myStats: action.status
            };
        case SET_USER_QUESTS_LIKED:
            if(action.stat==='quests')
            return {
                ...state,
                quests:state.quests.map(a => {
                    if (a.id === action.id){
                        return{...a, liked:action.liked}
                    }
                    return a
                })
            };
            else if(action.stat==='answers')
                return {
                    ...state,
                    answers:state.answers.map(a => {
                        if (a.quest.id === action.id){
                            return{...a, quest:{...a.quest, liked:action.liked}}
                        }
                        return a
                    })
                };
            else if(action.stat==='comments')
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.answer.quest.id === action.id){
                            return{...a, answer:{...a.answer,quest:{...a.answer.quest,liked:action.liked}}}
                        }
                        return a
                    })
                };
        case SET_USER_QUESTS_LIKES:
            if(action.stat==='quests')
            return {
                ...state,
                quests:state.quests.map(a => {
                    if (a.id === action.id){
                        return{...a, likes:action.likes}
                    }
                    return a
                })
            };
            else if(action.stat==='answers')
                return {
                    ...state,
                    answers:state.answers.map(a => {
                        if (a.quest.id === action.id){
                            return{...a, quest:{...a.quest,likes:action.likes}}
                        }
                        return a
                    })
                };
            else if(action.stat==='comments')
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.answer.quest.id === action.id){
                            return{...a, answer:{...a.answer,quest:{...a.answer.quest,likes:action.likes}}}
                        }
                        return a
                    })
                };
        case TOGGLE_USER_QUESTS_LIKE:
            if(action.stat==='quests')
            return {
                ...state,
                quests:state.quests.map(a => {
                    if (a.id === action.id){
                        if(a.liked){
                            return{
                                ...a,
                                liked:!a.liked,
                                likes:a.likes-1
                            }
                        }
                        return{
                            ...a,
                            liked:!a.liked,
                            likes:a.likes+1
                        };
                    }
                    return a
                })
            };
            else if(action.stat==='answers')
                return {
                    ...state,
                    answers:state.answers.map(a => {
                        if (a.quest.id === action.id){
                            if(a.quest.liked){
                                return{
                                    ...a,
                                    quest:{...a.quest,
                                        liked:!a.quest.liked,
                                        likes:a.quest.likes -1
                                    }

                                }
                            }
                            return{
                                ...a,
                                quest: {
                                    ...a.quest,
                                    liked: !a.quest.liked,
                                    likes: a.quest.likes + 1
                                }
                            };
                        }
                        return a
                    })
                };
            else if(action.stat==='comments')
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.answer.quest.id === action.id){
                            if(a.answer.quest.liked){
                                return{
                                    ...a,
                                    answer:{...a.answer,
                                        quest: {
                                            ...a.answer.quest,
                                            liked: !a.answer.quest.liked,
                                            likes: a.answer.quest.likes - 1
                                        }
                                    }

                                }
                            }
                            return{
                                ...a,
                                answer: {
                                    ...a.answer,
                                    quest: {
                                        ...a.answer.quest,
                                        liked: !a.answer.quest.liked,
                                        likes: a.answer.quest.likes + 1
                                    }
                                }
                            };
                        }
                        return a
                    })
                };
        case SET_USER_ANSWERS_LIKED:
            if(action.stat==='answers')
            return {
                ...state,
                answers:state.answers.map(a => {
                    if (a.id === action.id){
                        return{...a, liked:action.liked}
                    }
                    return a
                })
            };
            else if(action.stat==='comments')
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.answer.id === action.id){
                            return{...a, answer:{...a.answer,liked:action.liked}}
                        }
                        return a
                    })
                };
        case SET_USER_ANSWERS_LIKES:
            if(action.stat==='answers')
            return {
                ...state,
                answers:state.answers.map(a => {
                    if (a.id === action.id){
                        return{...a, likes:action.likes}
                    }
                    return a
                })
            };
            else if(action.stat==='comments')
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.answer.id === action.id){
                            return{...a, answer:{...a.answer,likes:action.likes}}
                        }
                        return a
                    })
                };
        case TOGGLE_USER_ANSWERS_LIKE:
            if(action.stat==='answers')
            return {
                ...state,
                answers:state.answers.map(a => {
                    if (a.id === action.id){
                        if(a.liked){
                            return{
                                ...a,
                                liked:!a.liked,
                                likes:a.likes-1
                            }
                        }
                        return{
                            ...a,
                            liked:!a.liked,
                            likes:a.likes+1
                        };
                    }
                    return a
                })
            };
            else if(action.stat==='comments')
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.answer.id === action.id){
                            if(a.answer.liked){
                                return{
                                    ...a,
                                    answer: {
                                        ...a.answer,
                                        liked: !a.answer.liked,
                                        likes: a.answer.likes - 1
                                    }
                                }
                            }
                            return{
                                ...a,
                                answer: {
                                    ...a.answer,
                                    liked: !a.answer.liked,
                                    likes: a.answer.likes + 1
                                }
                            };
                        }
                        return a
                    })
                };
        case SET_USER_COMMENTS_LIKED:
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.id === action.id){
                            return{...a, liked:action.liked}
                        }
                        return a
                    })
                };
        case SET_USER_COMMENTS_LIKES:
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.id === action.id){
                            return{...a, likes:action.likes}
                        }
                        return a
                    })
                };
        case TOGGLE_USER_COMMENTS_LIKE:
                return {
                    ...state,
                    comments:state.comments.map(a => {
                        if (a.id === action.id){
                            if(a.liked){
                                return{
                                    ...a,
                                    liked:!a.liked,
                                    likes:a.likes-1
                                }
                            }
                            return{
                                ...a,
                                liked:!a.liked,
                                likes:a.likes+1
                            };
                        }
                        return a
                    })
                };
        case TOGGLE_USER_POST_SUBSCRIPTION:
            if(action.statType==='userQuests')
                return{
                    ...state,
                    quests:state.quests.map(a => {
                        if (a.id === action.id){
                            return {
                                ...a,
                                subscribed: action.sub
                            }
                        }
                        return a
                    })
                }
            else if(action.statType==='userAnswers') {
                if (action.objType === 'answer')
                    return {
                        ...state,
                        answers: state.answers.map(a => {
                            if (a.id === action.id) {
                                return {
                                    ...a,
                                    subscribed: action.sub
                                }
                            }
                            return a
                        })
                    }
                else if (action.objType === 'question')
                    return{
                    ...state,
                        answers:state.answers.map(a => {
                            if (a.quest.id === action.id) {
                                return {
                                    ...a,
                                    quest:{...a.quest,subscribed: action.sub}
                                }
                            }
                            return a
                        })
                    }
            }
            else if(action.statType==='userComments'){
                if (action.objType === 'answer')
                    return {
                        ...state,
                        comments: state.comments.map(a => {
                            if (a.answer.id === action.id) {
                                return {
                                    ...a,
                                    answer:{...a.answer,quest:{...a.answer.quest},subscribed: action.sub}
                                }
                            }
                            return a
                        })
                    }
                else if (action.objType === 'question')
                    return {
                        ...state,
                        comments: state.comments.map(a => {
                            if (a.answer.quest.id === action.id) {
                                return {
                                    ...a,
                                    answer:{...a.answer,quest:{...a.answer.quest,subscribed: action.sub}}
                                }
                            }
                            return a
                        })
                    }
            }

        case TOGGLE_USER_QUEST_STOPPED:
                return {
                    ...state,
                    quests:state.quests.map(a => {
                        if (a.id === action.id){
                            return{...a, stopped:action.stopped}
                        }
                        return a
                    })
                };

        case SET_USER_NAME:
            return{
                ...state,
                name: action.name
            };
        case SET_USER_QUESTS:
            return{
                ...state,
                quests: action.quests
            };
        case SET_USER_ANSWERS:
            return{
                ...state,
                answers: action.answers
            };
        case SET_USER_COMMENTS:
            return{
                ...state,
                comments: action.comments
            };
        case SET_USER_USERNAME:
            return{
                ...state,
                username: action.name
            };
        case SET_STATS_TYPE:
            return{
                ...state,
                type: action.statstype
            };
        case ADD_USER_QUESTS:
            return{
                ...state,
                quests: state.quests.concat(action.quests)
            };
        case ADD_USER_ANSWERS:
            return{
                ...state,
                answers: state.answers.concat(action.answers)
            };
        case ADD_USER_COMMENTS:
            return{
                ...state,
                comments: state.comments.concat(action.comments)
            };
        case SET_USER_QUEST_REMOVED:
            return {
                ...state,
                quests:state.quests.filter(function(value, index, arr){ return value.id !==action.id;})
            };
        case SET_USER_ANSWER_REMOVED:
            return {
                ...state,
                answers:state.answers.filter(function(value, index, arr){ return value.id !==action.id;})
            };
        case SET_USER_COMMENT_REMOVED:
            return {
                ...state,
                comments:state.comments.filter(function(value, index, arr){ return value.id !==action.id;})
            };
        case SET_SUB_STAT_TYPE:
            return {
                ...state,
                sub:action.status
            };
        default:
            return state
    }
};

export default userPostReducer