import React, { useEffect, useState } from "react";
import {Button, Cell, Panel, PanelHeader, PanelSpinner, Div, Spinner} from "@vkontakte/vkui";
import {useDispatch, useSelector} from "react-redux";
import {toggleNeedRegister} from "../Redux/authReducer";
import Logo  from "../logo.png"


function InitPanel(props){

    const app = useSelector(state => state.app);
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const [noLocalstorage, setNoLocalstorage] = useState(false)

    useEffect(()=>{
        try{
            localStorage.getItem('supported')
        }
        catch(e){setNoLocalstorage(true)}
    },[])

    if (app.debug)
    return(
        <Panel id={props.id}>
            <PanelHeader>Инициализация приложения</PanelHeader>
            <Cell multiline={true}>
                errors: <br/>
                backend_error_code: {app.error.backend} <br/>
                query_params: {window.location.href} <br/><br/>
                    {JSON.stringify(app)} <br/><br/>
                    {JSON.stringify(auth)}
            </Cell>
            {false && app.info.installed === false ?
                <Button onClick={()=>{dispatch(toggleNeedRegister(true))}}>Установить приложение</Button> :

                <PanelSpinner/>
            }
        </Panel>
    )

    console.log('DEBUG',app.isYaAuthenticated)

    return(
        <Panel id={props.id}>
            <div style={{background:"#576E9F",height:"100vh", display:"flex",justifyContent:"center"}}>
                <div style={{position:"fixed",top:"15%"}}>
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <img style={{width:"40vw"}} src={Logo} alt="logo"/>
                    </div>
                    <div style={{color:"#fff",textAlign:"center",fontSize:52,fontWeight:500}}>Отвечайка</div>
                    {noLocalstorage&&
                        <div style={{padding:10,textAlign:"center",color:"#fff",fontSize:18}}>
                            Похоже Вы запустили браузер в режиме инкогнито! <br/>
                            "Отвечайка" не может работать в режиме инкогнито! <br/>
                            Пожалуйста, запустите браузер в нормальном режиме!
                        </div>
                    }
                    {app.isYaAuthenticated!==false?
                        <PanelSpinner/>:
                        <>
                            <div style={{marginTop:10,textAlign:"center",color:"#fff"}}>
                                Ищите ответ на серьёзный вопрос?<br/>
                                Или просто поболтать хотите?<br/>
                                Здесь можно найти и то и другое <br/>
                                Но сначала нужно <br style={{marginBottom:5}}/>
                                👇🏻
                            </div>
                            <div style={{marginTop:5,display:"flex",justifyContent:"center"}}>
                                <Button mode={"commerce"} onClick={window.ysdk.auth.openAuthDialog}>Войти в Яндекс</Button>
                            </div>
                        </>

                    }
                </div>
                </div>
        </Panel>
    )
}

export default InitPanel