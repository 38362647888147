import {loadAnswer, loadQuest} from "./questionReducer";
import {loadProfile} from "./profileReducer";
import {setPmCurrentUser} from "./pmReducer";
import {setActivePanel} from "./appReducer";

let initialState = {
    hash:{
        panel:null,
        type:null, //profile,question,answer...
        id:null //id or username
    },
    history:[], //{panel:"panelName",type:"contentType",id:"id"} history.unshift()
    position:0,
    doNotAdd:false
}


const HISTORY_PUSH = 'HISTORY_PUSH'
const INCREASE_POSITION = 'INCREASE_POSITION'
const TOGGLE_DO_NOT_ADD = 'TOGGLE_DO_NOT_ADD'
const SET_ROUTER_HASH = 'SET_ROUTER_HASH'

export const historyPush = (history) => ({type:HISTORY_PUSH,history})
export const increasePosition = () => ({type:INCREASE_POSITION})
export const toggleDoNotAdd = (status) => ({type:TOGGLE_DO_NOT_ADD,status})

const checkPreviousPage = (page,history) => !(history.length > 1 && history[1].panel === page.panel && history[1].type === page.type && history[1].id === page.id);

const setRouterHash = (hash) => ({type:SET_ROUTER_HASH,hash})

export const getRouterHash = (hash) => {
    return async (dispatch) => {
        if(hash)
        try{
            let hashObj = {
                panel:null,
                type:null,
                id:null
            }
            const parsed = hash.split('&')[0].split('/')
            if(parsed[1]==="@")
                hashObj = {
                    panel:'profile',
                    type:null,
                    id:parsed[2]
            }
            else if(parsed[1]==='pm')
                hashObj = {
                    panel:'chat',
                    type:null,
                    id:parsed[2]
                }
            else if(["q","a"].includes(parsed[1]))
                hashObj = {
                    panel:'question',
                    type:parsed[1]==='q'?'question':'answer',
                    id:parsed[2]
                }
            else if(parsed[1]==='gifts')
            hashObj = {
                panel:'gifts',
                type:null,
                id:null
        }
            dispatch(setRouterHash(hashObj))
        }

        catch (e) {
            console.log('Hash error')
        }
    }
}

export const loadHash = (hash) => {
    return async (dispatch) => {
        if (hash.panel === 'question') {
            hash.type === 'question' && dispatch(loadQuest(hash.id))
            hash.type === 'answer' && dispatch(loadAnswer(hash.id))
        } else if (hash.panel === 'profile') dispatch(loadProfile(hash.id))
        else if (hash.panel === 'chat') {
            dispatch(setPmCurrentUser(hash.id));
        }
        dispatch(setActivePanel(hash.panel))
    }
}


const routerReducer = (state=initialState,action) => {
    switch (action.type) {
        case HISTORY_PUSH:
            if(checkPreviousPage(action.history,state.history))
            return {
                ...state,
                history: state.position>0?[action.history,...state.history.slice(state.position,19)]:[action.history,...state.history.slice(0,19)],
                position:0
            };
            return state
        case INCREASE_POSITION:
            return{
                ...state,
                position: state.position + 1
            }
        case TOGGLE_DO_NOT_ADD:
            return{
                ...state,
                doNotAdd: action.status
            };
        case SET_ROUTER_HASH:
            return{
                ...state,
                hash: action.hash
            }
        default:
            return state
    }
};

export default routerReducer
