import React, {useEffect} from "react";
import {
    Avatar,
    Group,
    Panel,
    PanelHeader,
    Cell, RichCell, Button, Header, UsersStack, MiniInfoCell, SimpleCell, PanelSpinner, Div, CellButton
} from "@vkontakte/vkui";
import {UserStatus} from "../Shared/UserStatus";
import {
    Icon24Write,
    Icon24MoreHorizontal,
    Icon20HelpOutline,
    Icon20MessageOutline,
    Icon20CommentOutline,
    Icon20UsersOutline,
    Icon20Users3Outline,
    Icon16StarCircle
} from "@vkontakte/icons";
import BottomNav from "../Nav/BottomNav";
import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "../Redux/profileReducer";
import {checkDev, declOfNum} from "../utils/helpers";
import {setActiveModal, setActivePanel} from "../Redux/appReducer";
import {setStatsType, setUserName, toggleStatsFromNotes} from "../Redux/userPostsReducer";
import {setPmCurrentUser} from "../Redux/pmReducer";
import {historyPush, toggleDoNotAdd} from "../Redux/routerReducer";
import TopNav from "../Nav/TopNav";
import { Icon28AchievementCircleFillBlue,Icon28GiftCircleFillYellow } from '@vkontakte/icons';
import SiteHead from "../Shared/SiteHead";
import ProfileGifts from "./ProfileGifts";
import {setGiftUser, setShowcaseCategoryId} from "../Redux/giftsReducer";


function ProfilePanel(props){
    const auth = useSelector(state => state.auth);
    const profile = useSelector(state => state.profile);
    const doNotAdd = useSelector(state => state.router.doNotAdd);

    const dispatch = useDispatch();

    useEffect(()=>{
        if (profile.currentProfile)
            dispatch(getProfile(profile.currentProfile))
    },[profile.currentProfile]);
    //router
    useEffect(()=>{
        if(!doNotAdd){
            if (profile.currentProfile) dispatch(historyPush({
                panel: 'profile',
                type:null,
                id: profile.currentProfile
            }))
        }
        dispatch(toggleDoNotAdd(false))
    },[profile.currentProfile]);

    


    return(
        <Panel id={props.id}>
            <PanelHeader left={<TopNav/>}/>
            <SiteHead/>
                    {profile.data.warnings.length > 0 &&
                    <Group>
                        <SimpleCell
                            description={"Нажмите для просмотра"}
                            before={<Avatar src={'/media/avatars/banned.png'}/>}
                            onClick={()=>{dispatch(setActiveModal('warnings'))}}
                        >
                            У Вас {profile.data.warnings.length} {declOfNum(profile.data.warnings.length, ['предупреждение', 'предупреждения', 'предупреждений'])}
                        </SimpleCell>
                    </Group>
                    }

                    <Group>
                        <Cell disabled before={profile.data.userInfo.position.current&&<CellButton onClick={()=>{dispatch(setActivePanel('leaders'))}} after={<Icon28AchievementCircleFillBlue/>}> {profile.data.userInfo.position.current} </CellButton>}
                              after={<CellButton onClick={()=>{dispatch(setActivePanel('rate'))}} after={<Icon16StarCircle width={28} height={28} style={{color:"#6bc678"}}/>}>{profile.data.userInfo.rate}</CellButton>} style={{display:"flex",justifyContent:"flex-end"}}/>
                        <RichCell
                            disabled
                            before={<Avatar src={`${checkDev(profile.data.userInfo.avatar_small)}`} size={80}/>}
                            caption={<UserStatus
                                age = {profile.data.userInfo.age}
                                location={profile.data.userInfo.location}
                                status={profile.data.userInfo.status}
                            />}
                            //after={profile.data.is_friend&&<Icon28FavoriteCircleFillYellow/>}
                            actions={(profile.data.userInfo.username !== auth.user.username)&&
                                <React.Fragment>
                                    {!(profile.data.in_blacklist||profile.data.me_blacklisted)&&<Button onClick={()=>{
                                        dispatch(setPmCurrentUser(profile.currentProfile));
                                        dispatch(setActivePanel('chat'));
                                    }}>Написать</Button>}
                                    <Button mode="secondary" onClick={()=>{dispatch(setActiveModal('profileAction'))}}><Icon24MoreHorizontal/></Button>
                                </React.Fragment>
                            }
                        >
                            {profile.data.userInfo.name}
                        </RichCell>
                        {profile.data.userInfo.username !== auth.user.username &&
                        <Div>
                            <Button
                                before={<Icon28GiftCircleFillYellow/>} style={{background: "#e56a34"}} size={"l"}
                                stretched={true}
                                onClick={() => {
                                    dispatch(setGiftUser({
                                        name: profile.data.userInfo.name,
                                        username: profile.data.userInfo.username,
                                        avatar: profile.data.userInfo.avatar_small
                                    }));
                                    dispatch(setShowcaseCategoryId(null))
                                    dispatch(setActiveModal('giftShowcase'))
                                }}
                            ><span style={{color:"#fff"}}>Сделать подарок</span></Button>
                        </Div>
                        }
                    </Group>
            {profile.data.in_blacklist || profile.data.me_blacklisted ?
                <>
                    <Group>
                        {profile.data.in_blacklist && <Cell
                            disabled
                            description={'Вы добавили этого пользователя в чёрный список'}
                        />
                        }
                        {profile.data.me_blacklisted && <Cell
                            disabled
                            description={'Этот пользователь добавил Вас в чёрный список'}
                        />
                        }
                    </Group>
                </> :
                <>
                    {((profile.data.userInfo.username === auth.user.username) || profile.data.userInfo.user_quote) &&
                    <Group>
                        {profile.data.userInfo.username === auth.user.username &&
                        <Header mode="secondary">Нажмите для редактирования</Header>}
                        <Cell disabled={profile.data.userInfo.username !== auth.user.username}
                              multiline={true} before={(profile.data.userInfo.username === auth.user.username) ?
                            <Icon24Write size={20}/> : null}
                              description={profile.data.userInfo.user_quote}
                              onClick={() => {
                                  if (profile.data.userInfo.username === auth.user.username) dispatch(setActiveModal('status'))
                              }}
                        />
                    </Group>
                    }
                    {profile.data.presents && profile.data.presents.length > 0 &&
                    <Group>
                        <ProfileGifts
                            gifts={profile.data.presents}
                            showUserGift={
                                ()=>{
                                    dispatch(setGiftUser({name:profile.data.userInfo.name,username:profile.data.userInfo.username,avatar:profile.data.userInfo.avatar_small}));
                                    dispatch(setActivePanel('gifts'))
                                }
                            }
                        />
                    </Group>
                    }
                    {profile.statsFetching ?
                        <PanelSpinner/> :
                        <Group>
                            <MiniInfoCell
                                before={<Icon20HelpOutline/>}
                                textWrap="full"
                                textLevel="primary"
                                onClick={() => {
                                    dispatch(toggleStatsFromNotes(false))
                                    dispatch(setUserName(profile.data.userInfo.username))
                                    dispatch(setStatsType('quests'))
                                    dispatch(setActivePanel('stats'))
                                }}
                            >
                                {profile.data.userInfo.userStats.q} {declOfNum(profile.data.userInfo.userStats.q, ['Вопрос', 'Вопроса', 'Вопросов'])}
                            </MiniInfoCell>
                            <MiniInfoCell
                                before={<Icon20MessageOutline/>}
                                textWrap="full"
                                textLevel="primary"
                                onClick={() => {
                                    dispatch(toggleStatsFromNotes(false))
                                    dispatch(setUserName(profile.data.userInfo.username))
                                    dispatch(setStatsType('answers'))
                                    dispatch(setActivePanel('stats'))
                                }}
                            >
                                {profile.data.userInfo.userStats.a} {declOfNum(profile.data.userInfo.userStats.a, ['Ответ', 'Ответа', 'Ответов'])}
                            </MiniInfoCell>
                            <MiniInfoCell
                                before={<Icon20CommentOutline/>}
                                textWrap="full"
                                textLevel="primary"
                                onClick={() => {
                                    dispatch(toggleStatsFromNotes(false))
                                    dispatch(setUserName(profile.data.userInfo.username))
                                    dispatch(setStatsType('comments'))
                                    dispatch(setActivePanel('stats'))
                                }}
                            >
                                {profile.data.userInfo.userStats.c} {declOfNum(profile.data.userInfo.userStats.c, ['Комментарий', 'Комментария', 'Комментариев'])}
                            </MiniInfoCell>
                            <MiniInfoCell
                                before={<Icon20UsersOutline/>}
                                textWrap="full"
                                textLevel="primary"
                                after={
                                    <UsersStack
                                        photos={profile.data.friends ? profile.data.friends.map((el) => (checkDev(el.avatar))) : []}
                                    />}
                                onClick={() => {
                                    dispatch(setActiveModal('friends'))
                                }}
                            >
                                {profile.data.userInfo.userStats.f} {declOfNum(profile.data.userInfo.userStats.f, ['Друг', 'Друга', 'Друзей'])}
                            </MiniInfoCell>
                            <MiniInfoCell
                                before={<Icon20Users3Outline/>}
                                textWrap="full"
                                textLevel="primary"
                                after={
                                    <UsersStack
                                        photos={profile.data.subs ? profile.data.subs.map((el) => (checkDev(el.avatar))) : []}
                                    />}
                                onClick={() => {
                                    dispatch(setActiveModal('subs'))
                                }}
                            >
                                {profile.data.userInfo.userStats.s} {declOfNum(profile.data.userInfo.userStats.s, ['Подписчик', 'Подписчика', 'Подписчиков'])}
                            </MiniInfoCell>

                        </Group>
                    }
                </>
            }
                    {profile.data.userInfo.username !== auth.user.username?
                    <Group className={'lastElement'}>
                        <Header mode="secondary">Нажмите для редактирования</Header>
                        <Cell multiline={true} before={<Icon24Write size={20}/>}
                              description={'Здесь Вы можете оставить комментарий о пользователе. Комментарий будет виден только Вам.'}
                              onClick={()=>{dispatch(setActiveModal('note'))}}
                        >
                            {profile.data.note}
                        </Cell>
                    </Group>:
                        <div className={'lastElement'}/>
                    }
                    <BottomNav/>
        </Panel>
    )
};

export default ProfilePanel