import React, {useEffect, useState} from "react";
import { Form, Field } from "react-final-form";
import {Div, Button, Chip, File, FormItem, FormLayout, Group, MiniInfoCell} from "@vkontakte/vkui";
import {maxLength, required, sendByKey} from "./Validators";
import {FinalFormCheckbox, FinalFormTextArea} from "./FormControls";
import Style from "./Forms.module.css"
import {
    deletePostImage,
    getRandomQuest,
    getTags,
    loadRandomQuest,
    sendAnswer,
    sendComment,
    sendQuest, setQuestionAdded, skip
} from "../Redux/questionReducer";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../Redux/appReducer";
import {Icon20FireCircleFillRed, Icon28PictureOutline, Icon20CancelCircleFillRed, Icon20FavoriteCircleFillGreen, Icon20FavoriteCircleFillYellow} from '@vkontakte/icons';
import loadingImage from "../Images/picture.png"
import {ReactComponent as Coin} from "../Icons/coin.svg";
import TimeToNextDay from '../Shared/TimeToNextDay';

const maxLength250 = maxLength(250)
const maxLength1000 = maxLength(1000)


function SendForm(props) {
    const validate = (val) => {
        const req = required(val)
        let max
        if(props.type==='question') max = maxLength1000(val)
        else max = maxLength250(val)
        if (!req && !max) return undefined
        else if (req) return (req)
        else if (max) return (max)
    }
    const app = useSelector(state => state.app);
    const auth = useSelector(state => state.auth);
    const quest = useSelector(state => state.quest);
    const dispatch = useDispatch()
    const [file,setFile] = useState({data:{},dataUrl:null});
    const types = {f:'streams',a:'answers',c:'comments'}
    const onCancel = () => {
        if(props.onCancel) props.onCancel()
        else if(['f','a','c'].includes(quest.noteType)) dispatch(skip({id:quest.answer.id,type:types[quest.noteType]})).then((response)=>{
            if(response===false) dispatch(getRandomQuest())
        })
        else dispatch(getRandomQuest(quest.quest.id))
    }
    const wallet = parseInt(useSelector(state => state.notifs.notifs.w));
    const noPriorityQuest = useSelector(state => state.notifs.notifs.pr);
    const anonCost = 200
    const overRateCost = 50
    const anonTooExpensive = anonCost>wallet
    const overRateTooExpensive = overRateCost>wallet

    const onSubmit = (val) => {
        if(!['quest','answer','comment'].includes(props.fieldName)||props.edit) props.onSubmit(val)
        else {
            let formData = new FormData();
            formData.append(props.fieldName, val[props.fieldName])
            formData.append('convert','True')
            formData.append('anonymous',!!val.anonymous)
            formData.append('overrate',!!val.overrate)
            if (file && file.data) {
                formData.append('image', file.data)
            }
            if (props.type === 'question') {
                const tagsId = tags.map(el => el.id)
                if (props.edit) formData.append('tags', tagsId)
                else formData.append('tags', JSON.stringify(tagsId))

                if (props.onSubmit) props.onSubmit(formData)
                else {
                    dispatch(sendQuest(formData)).then((result) => {
                        dispatch(getRandomQuest())
                        //dispatch(loadQuest(result))
                        dispatch(setActivePanel('question'))
                        dispatch(setQuestionAdded(result))
                    })
                }
            } else if (props.onSubmit) props.onSubmit(formData)
            else if (props.type === 'answer')
                dispatch(sendAnswer(quest.quest.id, formData)).then(() => dispatch(getRandomQuest(auth.token.access_token, quest.quest.id)))
            else if (props.type === 'comment')
                dispatch(sendComment(quest.answer.id, formData, ['f', 'a', 'c'].includes(quest.noteType) ? types[quest.noteType] : null)).then(() => dispatch(getRandomQuest()))
        }
    }

    const preview = (e) => {
        if (e.target&&e.target.files&&e.target.files.length>0) {
            setFile({data:e.target.files[0],dataUrl:URL.createObjectURL(e.target.files[0])})
        }
    }

    useEffect(() => {
        if (props.type === 'question')
            dispatch(getTags())
    }, [props.type]);
    const [tags, setTags] = useState(props.initialValues&&props.initialValues.tags?props.initialValues.tags:[]);

    const addTag = (tag) => {
        if (!tags.some(el => el.id === tag.id))
            setTags(prevState => prevState.concat(tag))
    }

    const removeTag = (tag) => {
        setTags(prevState => prevState.filter(o => (o.id !== tag.id)))
    }

    let allTags,questTags
    if (props.type === 'question') {
        allTags = quest.tags.map((el) => (
            <Chip
                className={Style.tag}
                key={el.id}
                removable={false}
                before={'#'}
                onClick={() => {
                    addTag(el)
                }}
            >
                {el.name}
            </Chip>
        ))

        questTags = tags.map((el) => (
            <Chip
                className={Style.tag}
                key={el.id}
                removable={false}
                before={'#'}
                onClick={() => {
                    removeTag(el)
                }}
            >
                {el.name}
            </Chip>
        ))
}
    return(
        <Form
            initialValues={props.initialValues}
            onSubmit={(val,form)=>{onSubmit(val);setTimeout(form.reset)}}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                  <FormLayout id={"sendForm"} onSubmit={handleSubmit}>
                      {props.type === 'question' && <>
                          <MiniInfoCell
                              before={<Icon20FireCircleFillRed />}
                              textWrap="full"
                              textLevel="secondary"
                          >
                              Вопросы содержащие мат, или затрагивающие политические, эротические и религиозные темы должны иметь соответсвующий тег. Нажмите на тег ниже, чтобы добавить его к вопросу, если это нужно.
                          </MiniInfoCell>
                      <Group separator={'hide'} header={<span className={Style.tags}>Доступные теги:</span>}>
                          {allTags}
                      </Group>
                      </>
                      }

                      <FormItem top={<div style={{display:"flex",justifyContent:"space-between"}}>{(file.data && file.data.size/1024/1024>4)?<div style={{marginTop:"auto",marginBottom:"auto", color:"#e64646"}}>Размер файла слишком большой!</div>:<div style={{marginTop:"auto",marginBottom:"auto"}}>Напишите что ни будь</div>}{['quest','answer','comment'].includes(props.fieldName)&&<File mode={"secondary"} accept="image/*" onChange={preview}><Icon28PictureOutline/></File>}</div>}>
                          <Field component={FinalFormTextArea}
                                 type={"text"}
                                 name={props.fieldName} placeholder={props.placeholder}
                                 validate={['answer','comment'].includes(props.type)?required:validate}
                                 disabled={app.inProgress||props.formDisabled}
                                 onKeyDown={(e)=>{if(!auth.settings.disable_enter)sendByKey(e,handleSubmit)}}
                              />
                      </FormItem>
                      {props.type==='question'&&<><MiniInfoCell
                              before={<Icon20FavoriteCircleFillGreen />}
                              textWrap="full"
                              textLevel="secondary"
                          >
                              <div>
                                <div style={{display:"flex",justifyContent:"space-between"}}>
                                  <div>У Вас есть</div>
                                  <div className={Style.anonCost}> <div style={{fontWeight:"500",marginRight:3,marginTop:"auto",marginBottom:"auto",fontSize:18,color :"#6bc678"}}>{wallet}</div> <div style={{display:"flex",marginTop:"auto",marginBottom:"auto"}}><Coin style={{width:20,height:20}}/></div></div>
                                </div>
                                <div style={{marginTop:5}}>{anonTooExpensive?"У Вас недостаточно монет, чтобы задать анонимный вопрос, но вы можете задать обычный вопрос.":"Вы можете задать вопрос анонимно. Комментируя ответы к этому вопросу Вы также будете оставаться анонимным."}</div>
                              </div>
                    </MiniInfoCell>
                    <FormItem
                      >
                        <Field component={FinalFormCheckbox}
                               name={'anonymous'}
                               type={"checkbox"}
                               disabled={anonTooExpensive}
                        >
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div>Анонимно</div>
                                <div className={Style.anonCost}> <div style={{fontWeight:"500",marginRight:3,marginTop:"auto",marginBottom:"auto",fontSize:18,color: anonTooExpensive?"rgb(255, 49, 49)":"#6bc678"}}>{anonCost}</div> <div style={{display:"flex",marginTop:"auto",marginBottom:"auto"}}><Coin style={{width:20,height:20}}/></div></div>
                            </div>
                        </Field>

                    </FormItem></>}
                      {(file.dataUrl||props.image) && <Div style={{display:"flex"}}>
                          <div className={Style.thumb}>
                          <div className={Style.thumbInner}>
                              <img  className={Style.preview} alt={'preview'} src={props.image?props.image:file.dataUrl}/>
                              <Icon20CancelCircleFillRed style={{position:"absolute",top:0,right:0,cursor:"pointer"}} onClick={()=>{
                                  if(props.image)dispatch(deletePostImage(props.imageType,props.id))
                                  else setFile({data:{},dataUrl:null})
                              }}/>
                          </div>
                          </div>
                      </Div>}
                      {(props.image_processing) && <Div style={{display:"flex"}}>
                          <div className={Style.thumb}>
                              <div className={Style.thumbInner}>
                                  <img  className={Style.preview} alt={'preview'} src={loadingImage}/>
                                  <Icon20CancelCircleFillRed style={{position:"absolute",top:0,right:0,cursor:"pointer"}} onClick={()=>{
                                      dispatch(deletePostImage(props.imageType,props.id))
                                  }}/>
                              </div>
                          </div>
                      </Div>}
                      {props.type==='question'&&
                      <>
                          <Group header={<span className={Style.tags}>Теги вопроса:</span>}>
                              {questTags}
                          </Group>
                      <div className={Style.answerButtons}>
                          <FormItem>
                              <Button onClick={handleSubmit} size="m"
                              >{props.edit?'Сохранить':'Задать'}</Button>
                          </FormItem>
                          <FormItem>
                              <Button onClick={(e)=>{
                                  e.preventDefault()
                                  if(props.onCancel) props.onCancel()
                                  else {
                                      dispatch(setActivePanel('question'));
                                      dispatch(loadRandomQuest())
                                  }

                              }} size="m"
                              >Отмена</Button>
                          </FormItem>
                      </div>
                      {noPriorityQuest&&<><MiniInfoCell
                            before={<Icon20FavoriteCircleFillYellow />}
                            textWrap="full"
                             textLevel="secondary"
                           >
                             <div>На сегодня лимит вопросов исчерпан. Вы можете задавать вопросы, но они будут получать меньше просмотров.</div>
                             <div>Лимит вопросов обнулится через <TimeToNextDay/></div>
                             <div style={{marginTop:5}}>{overRateTooExpensive?"У Вас недостаточно монет, чтобы чтобы получить больше просмотров.":"Вы можете получить больше просмотров."}</div>
                            </MiniInfoCell>
                            <FormItem
                      >
                        <Field component={FinalFormCheckbox}
                               name={'overrate'}
                               type={"checkbox"}
                               disabled={overRateTooExpensive}
                        >
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                <div>Хочу больше просмотров</div>
                                <div className={Style.anonCost}> <div style={{fontWeight:"500",marginRight:3,marginTop:"auto",marginBottom:"auto",fontSize:18,color: overRateTooExpensive?"rgb(255, 49, 49)":"#6bc678"}}>{overRateCost}</div> <div style={{display:"flex",marginTop:"auto",marginBottom:"auto"}}><Coin style={{width:20,height:20}}/></div></div>
                            </div>
                        </Field>

                    </FormItem>
                            </>}
                      </>
                      }
                      {['answer','comment'].includes(props.type)&&
                          <div className={Style.answerButtons}>
                              <FormItem>
                          <Button disabled={props.formDisabled||(file.data && file.data.size/1024/1024>4)} onClick={handleSubmit} size="m"
                                  >{props.edit?'Сохранить':props.button}</Button>
                              </FormItem>
                              <FormItem>
                          <Button onClick={(e)=>{e.preventDefault();onCancel()}} size="m"
                                  >{props.edit?'Отмена':'Пропустить'}</Button>
                              </FormItem>
                          </div>
                      }
                      {['status', 'note', 'abuse','broadcast'].includes(props.type) &&
                      <FormItem>
                          <Button disabled={props.formDisabled} onClick={handleSubmit} size="l"
                                  stretched>{['abuse','broadcast'].includes(props.type) ? 'Отправить' : 'Сохранить'}</Button>
                      </FormItem>
                      }
                  </FormLayout>
              )}
        />
    )
}

export default SendForm
