import React, {useState} from "react";
import {Avatar, Button, Cell, CellButton, Div, Group, Header, Tabs, TabsItem, Title} from "@vkontakte/vkui";
import EditForm from "../Forms/SettingsForm";
import NotesForm from "../Forms/NotesForm";
import {updateInterface, updateNotifications} from "../Redux/settingsReducer";
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../Redux/appReducer";
import {loadRandomQuest} from "../Redux/questionReducer";
import {Icon28Notifications, Icon28MailCircleFillGray, Icon28HeadphonesOutline} from "@vkontakte/icons";
import {setEmail} from "../Redux/authReducer";
import {checkDev} from "../utils/helpers";
import {setPmCurrentUser} from "../Redux/pmReducer";
import {loadProfile} from "../Redux/profileReducer";


const EditSettings = (props) => {

    const dispatch=useDispatch()
    const email = useSelector(state => state.auth.user.email)
    const new_email = useSelector(state => state.auth.new_email)
    const email_error = useSelector(state => state.auth.new_email_error)


    const [secondTab, setSecondTab] = useState(1);
    return(
        <Div>
            {false&&<Tabs>
                <TabsItem
                    selected={secondTab===1}
                    onClick={()=>{setSecondTab(1)}}
                >
                    Интерфейс
                </TabsItem>
                <TabsItem
                    selected={secondTab===2}
                    onClick={()=>{setSecondTab(2)}}
                >
                    Уведомления
                </TabsItem>
            </Tabs>}
            {secondTab===1&&<>
                <Cell disabled description={"Здесь можно изменить настройки сайта."}/>
                <EditForm
                    onSubmit={(val)=>{dispatch(updateInterface(val)).then(()=>{props.setShowSnackbar(true)})}}
                    initialValues={props.interface}
                    onCancel={()=>{
                        dispatch(setActivePanel('question'));
                        dispatch(loadRandomQuest())
                    }}
                />
            </>}

            {secondTab===2&&<>
                <Cell disabled description={"Здесь можно включить или отключить уведомления по e-mail."}/>
                {!(email||new_email) && email_error.id &&
                <Group header={<Header mode="secondary">Email уже используется</Header>}>
                    <Div>Email {email_error.email} уже используется другой учетной записью</Div>
                    <Cell
                        before={<Avatar src={checkDev(email_error.avatar_small)}/>}
                        onClick={()=>{
                            dispatch(loadProfile(email_error.username));
                            dispatch(setActivePanel('profile'))
                        }}
                    >{email_error.name}</Cell>
                    <Div>Если Вы думаете, что это ошибка, или хотите доступ к этой учетной записи - напишите в поддержку</Div>
                    <Div><Button mode={"tertiary"} before={<Icon28HeadphonesOutline width={18} height={18}/>}
                                 onClick={()=>{
                                     dispatch(setPmCurrentUser('moderator'));
                                     dispatch(setActivePanel('chat'));
                                 }}
                    >Написать в поддержку</Button></Div>
                </Group>
                }
                {email&&props.notifications.disable_all_notifs&&
                    <>
                        <Title level="2" weight="semibold" style={{ textAlign:"center" }}>Уведомления отключены</Title>
                        <Title level="3" weight="medium" style={{ textAlign:"center" }}>
                            Ваш почтовый ящик не принимает наших писем.<br/>
                            Возможно Вы жаловались на спам, или Ваша почта была заблокирована.
                        </Title>
                    </>
                }

                {(email||new_email) && !props.notifications.disable_all_notifs && <NotesForm
                    onSubmit={(val) => {
                        dispatch(updateNotifications(val)).then(() => {
                            props.setShowSnackbar(true)
                        })
                    }}
                    initialValues={props.notifications}
                    onCancel={() => {
                        dispatch(setActivePanel('question'));
                        dispatch(loadRandomQuest())
                    }}
                />
                }
            </>}

        </Div>
    )
}

export default EditSettings