import React, {useEffect, useState} from "react";
import {Avatar, Panel, PanelHeader, ScreenSpinner, Snackbar, Tabs, TabsItem} from "@vkontakte/vkui";
import BottomNav from "../Nav/BottomNav";
import EditProfile from "./EditProfile";
import EditSettings from "./EditSettings";
import EditIgnore from "./EditIgnore";
import {useDispatch, useSelector} from "react-redux";
import {getAnket} from "../Redux/settingsReducer";
import {Icon16Done} from "@vkontakte/icons";
import TopNav from "../Nav/TopNav";


const SettingsPanel = (props) => {
    const [currentTab, setCurrentTab] = useState(1);
    const settings = useSelector(state => state.settings);
    const dispatch = useDispatch()

    const [showSnackBar, setShowSnackbar] = useState(false);

    useEffect(()=>{
        dispatch(getAnket())
    },[]);

    return(
        <Panel id={props.id}>
            <PanelHeader
                left={<TopNav/>}
            />
            <Tabs>
                <TabsItem
                    selected={currentTab===1}
                    onClick={()=>{setCurrentTab(1)}}
                >
                    Профиль
                </TabsItem>
                <TabsItem
                    selected={currentTab===2}
                    onClick={()=>{setCurrentTab(2)}}
                >
                    Интерфейс
                </TabsItem>
                <TabsItem
                    selected={currentTab===3}
                    onClick={()=>{setCurrentTab(3)}}
                >
                    Игнор
                </TabsItem>
            </Tabs>


            {currentTab===1&&
                <EditProfile
                    setPopout={props.setPopout}
                    profileUpdating={settings.profileUpdating}
                    anket={settings.anket}
                    setShowSnackbar={setShowSnackbar}
                />
            }
            {currentTab===2&&
                <EditSettings
                    profileUpdating={settings.profileUpdating}
                    interface={settings.settings.interface}
                    notifications={settings.settings.notifications}
                    setShowSnackbar={setShowSnackbar}
                />
            }
            {currentTab===3&&
                <EditIgnore
                    profileUpdating={settings.profileUpdating}
                    ignore={settings.ignore}
                    setPopout={props.setPopout}
                    setShowSnackbar={setShowSnackbar}
                />
            }

            {showSnackBar && <Snackbar
                onClose={() => setShowSnackbar(false)}
                action="Закрыть"
                onActionClick={() => setShowSnackbar(false)}
                before={<Avatar size={24} style={{background: '#3f8ae0'}}><Icon16Done fill="#fff" width={14}
                                                                                   height={14}/></Avatar>}
            >
                Профиль сохранен
            </Snackbar>
            }

            {settings.profileUpdating&&<ScreenSpinner/>}

            <div className={'lastElement'}/>
            <BottomNav/>
        </Panel>
    )
}


export default SettingsPanel