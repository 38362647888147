import {Icon28Send, Icon28PictureOutline, Icon20CancelCircleFillRed} from '@vkontakte/icons';
import { Div, FixedLayout, IconButton } from "@vkontakte/vkui"
import React, { useRef, useState } from "react"
import { Field, Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { getRandomQuest, sendAnswer, sendComment } from '../Redux/questionReducer';
import { FinalFormChatField } from './FormControls';
import { required, sendByKey } from './Validators';
import Style from "./Forms.module.css"



const StickyForm = (props) => {
    const ref=useRef()
    const quest = useSelector(state => state.quest);

    const scheme = useSelector(state => state.app.scheme);
    const dispatch = useDispatch()
    const [file,setFile] = useState({data:{},dataUrl:null});
    const types = {f:'streams',a:'answers',c:'comments'}
    const disable_enter = useSelector(state => state.auth.settings.disable_enter);

    const preview = (e) => {
        if (e.target&&e.target.files&&e.target.files.length>0) {
            setFile({data:e.target.files[0],dataUrl:URL.createObjectURL(e.target.files[0])})
        }
    }

    const onSubmit = (val) => {
            let formData = new FormData();
            formData.append(props.fieldName, val[props.fieldName])
            formData.append('convert','True')
            if (file && file.data) {
                formData.append('image', file.data)
            }
            if (props.type === 'answer')
                dispatch(sendAnswer(quest.quest.id, formData)).then(() => dispatch(getRandomQuest(quest.quest.id)))
            else if (props.type === 'comment')
                dispatch(sendComment(quest.answer.id, formData, ['f', 'a', 'c'].includes(quest.noteType) ? types[quest.noteType] : null)).then(() => dispatch(getRandomQuest()))
    }

    return(
        <FixedLayout vertical="bottom" style={{bottom:45}} id={"stickyForm"}>
            <input type={"file"} ref={ref} style={{display:"none"}} onChange={preview}/>
            <Div style={{display:"flex", justifyContent:"space-between",backgroundColor:scheme==='space_gray'?"#2c2d2e":"#fff"}}>
            <IconButton onClick={()=>{ref.current.click()}}><Icon28PictureOutline/></IconButton>
            <Form
                initialValues={props.initialValues}
                onSubmit={(val,form)=>{onSubmit(val);setTimeout(form.reset)}}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <Field component={FinalFormChatField}
                        type={"text"}
                        name={props.fieldName} placeholder={props.placeholder}
                        handleSubmit={handleSubmit}
                        validate={required}
                        scheme={scheme}
                        onKeyDown={(e)=>{if(!disable_enter)sendByKey(e,handleSubmit)}}
                //disabled={app.inProgress}
                //onKeyDown={(e)=>{
                //    handleKeyDown(e,handleSubmit)
                //}}
         />
              )}
              />
            </Div>
            {(file.dataUrl||props.image) && <Div style={{display:"flex",backgroundColor:scheme==='space_gray'?"#2c2d2e":"#fff"}}>
                          <div className={Style.thumb}>
                          <div className={Style.thumbInner}>
                              <img  className={Style.preview} alt={'preview'} src={props.image?props.image:file.dataUrl}/>
                              <Icon20CancelCircleFillRed style={{position:"absolute",top:0,right:0,cursor:"pointer"}} onClick={()=>{setFile({data:{},dataUrl:null})}}/>
                          </div>
                          </div>
                      </Div>}
        </FixedLayout>
    )
}

export default StickyForm