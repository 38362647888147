import {useEffect, useRef} from "react";

export let declOfNum = (number, titles) => {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

export let checkDev = (url,ws=false) => {
    //if(ws)return(`wss://${window.location.hostname}${url}`)
    //return (`https://${window.location.hostname}${url}`)
    if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        if (ws) return (`wss://local-test.otvechai.com${url}`)
        return (`https://local-test.otvechai.com${url}`)
    }
    else if (ws) return(`wss://ya.otvechai.com${url}`)
    return(url)
};

export function useEventListener(eventName, handler, element = window){

    // Create a ref that stores handler

    const savedHandler = useRef();

    // Update ref.current value if handler changes.

    // This allows our effect below to always get latest handler ...

    // ... without us needing to pass it in effect deps array ...

    // ... and potentially cause effect to re-run every render.

    useEffect(() => {

        savedHandler.current = handler;

    }, [handler]);

    useEffect(

        () => {

            // Make sure element supports addEventListener

            // On

            const isSupported = element && element.addEventListener;

            if (!isSupported) return;



            // Create event listener that calls handler function stored in ref

            const eventListener = event => savedHandler.current(event);



            // Add event listener

            element.addEventListener(eventName, eventListener);



            // Remove event listener on cleanup

            return () => {

                element.removeEventListener(eventName, eventListener);

            };

        },

        [eventName, element] // Re-run if eventName or element changes

    );

};

export const timeHumanize = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const now = new Date()
    const minutes = Math.floor((now-date) /60000)
    if(minutes===0)
        return('только что')
    else if(minutes>0 && minutes<60)
        return  `${minutes} ${declOfNum(minutes,['минута','минуты','минут'])} назад`
    else if(minutes>60 && minutes<1440) {
        const hours = Math.floor(minutes / 60)
        return `${hours} ${declOfNum(hours,['час','часа','часов'])} назад`
    }
    else if(minutes>1440 && minutes < 2880)
        return('1 день назад')
    else return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth()+1)).slice(-2)}.${date.getFullYear()}`

}

export const concatUniq = (array,addon) => {
    if(!Array.isArray(addon))
        addon=[addon]
    const ids = new Set(array.map(d => d.id));
    return [...array, ...addon.filter(d => !ids.has(d.id))];
}

export const scrollToRef = (offset,ref,smooth=true) => {
    if (ref && typeof ref.scrollTo === 'function'){
        smooth? ref.scrollTo({top: offset, behavior: 'smooth'}): ref.scrollTo({top: offset})
    }
    else if (ref && typeof ref.scrollTop === 'number') ref.scrollTop = offset
    else console.log('Scroll Not supported')
}

export const findScrollContainer = (element) => {
    if (!element) {
        return undefined;
    }

    let parent = element.parentElement;
    while (parent) {
        const { overflow } = window.getComputedStyle(parent);
        if (overflow.split(' ').every(o => o === 'auto' || o === 'scroll')) {
            return parent;
        }
        parent = parent.parentElement;
    }

    return document.documentElement;
};

export const detectCookieSupport = () => {
    const date = new Date();
    date.setTime(date.getTime() + (5*60*1000));
    document.cookie = `supportCookie=1; path=/; expires=True; sameSite=None; secure=True; expires=${date.toUTCString()}`
}

export function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
}

export const checkRemoved = (removed) => {
    if (typeof removed === 'object') return removed.author||removed.staff
    return removed
}

export const checkAddSupported = (client,platform,version) => {
    try{
        return client === 'vk'|| (platform === 'android' && parseFloat(version.replace(".","")) >= 222.14)
    }
    catch (e) {
        return false
    }
}