import * as axios from "axios";
import {checkDev, detectCookieSupport} from "../utils/helpers";
import Cookies from 'js-cookie';


const api = axios.create({
    responseType: 'application/json',
    baseURL:`${checkDev('/api')}`,
    timeout:60000,
    withCredentials: true,

});

const apiFiles = axios.create({
    baseURL:`${checkDev('/api')}`,
    timeout:60000,
    ContentType:'multipart/form-data',
    withCredentials: true,
});


const tokens = axios.create({
    responseType: 'application/json',
    baseURL:`${checkDev('/api')}`,
    timeout:60000,
    withCredentials: true,
})

/*const config = {
    headers: { Authorization: `Bearer ${Cookies.get('access_token')}` }
};*/

const refreshToken = async () => {
    const refresh_token = localStorage.getItem('refresh_token')
    if (refresh_token) {
        try {
            const new_tokens = await axios.post(checkDev('/api/auth/jwt/refresh/'), {refresh: refresh_token}, {
                responseType: 'application/json',
                timeout: 60000
            })
            if (new_tokens.status === 200) {
                localStorage.setItem('access_token', new_tokens.data.access)
                localStorage.setItem('refresh_token', new_tokens.data.refresh)
                return (new_tokens.data.access)
            }
        }
        catch (e){
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            return null
        }
    }
    else return null
}

const responseSuccess = (response) => response

const responseError = async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        detectCookieSupport()
        try{
        let response = await tokens.get('auth/refresh')
        if (!response.data.cookies&&response.data.tokens){
            localStorage.setItem('access_token',response.data.tokens.access_token)
            localStorage.setItem('refresh_token',response.data.tokens.refresh_token)
            originalRequest.headers['Authorization'] = `Bearer ${response.data.tokens.access_token}`;
        }
        else if(response.data.cookies)
            originalRequest.headers['Authorization'] = `Bearer ${Cookies.get('access_token')}`;
    }
       catch(e){localStorage.setItem('token_error',1)}
        originalRequest._retry = true;

        return axios(originalRequest);
    }
    return Promise.reject(error);
}

const setTokenInterceptor = options => async config => {
    let access_token = Cookies.get('access_token')
    if(!access_token) access_token = localStorage.getItem('access_token')
    if(access_token) config.headers.Authorization = `Bearer ${access_token}`
    else{
        let response = await tokens.get('auth/refresh')
        if (!response.data.cookies&&response.data.tokens){
            localStorage.setItem('access_token',response.data.tokens.access_token)
            localStorage.setItem('refresh_token',response.data.tokens.refresh_token)
            config.headers.Authorization = `Bearer ${response.data.tokens.access_token}`
        }
        else if(response.data.cookies) config.headers.Authorization = `Bearer ${Cookies.get('access_token')}`

    }
    return config;
};

const injectRefreshTokenInterceptor = options => async config => {
    const refresh_token = localStorage.getItem('refresh_token')
    if(refresh_token) config.headers.Authorization = `Refresh ${refresh_token}`
    return config
}

const setToken = setTokenInterceptor('hello');
const injectRefreshToken = injectRefreshTokenInterceptor('hello');


api.interceptors.response.use(responseSuccess,responseError)
api.interceptors.request.use(setToken)
apiFiles.interceptors.response.use(responseSuccess,responseError)
apiFiles.interceptors.request.use(setToken)
tokens.interceptors.request.use(injectRefreshToken)
/*
const setTokenInterceptor = options => async config => {
    const access_token = localStorage.getItem('access_token')
    if(isTokenValid(access_token)) config.headers.Authorization = `Bearer ${access_token}`
    else{
        const new_token = await refreshToken()
        if(new_token) config.headers.Authorization = `Bearer ${new_token}`
    }
    return config;
};

const setToken = setTokenInterceptor('hello');
api.interceptors.request.use(setToken);
 */

const otvechaiMiniApi = {
    login: (sign) => {
        detectCookieSupport()
        return tokens.post(`auth/ya`, {sign}).then(response => {
            return response
        })
    },
    refresh: () => {
        detectCookieSupport()
        return tokens.get(`auth/refresh`).then(response => {
            return response
        })
    },
    register: (url,info) => {
        detectCookieSupport()
        return tokens.post(`auth/register`,{url,info})
    },
    register_bk: (vk_token,backend) => {
        return tokens.post(`auth/register/${backend}`,{token:vk_token})
    },
    whoAmI:(token) => {
        return api.get(`whoami`).then(response => {
            return response
        })
    },
    getProfile:(username) => {
        return api.get(`profile/${username}?mini=1`).then(response => {
            return response
        })
    },
    getProfileStats:(username) => {
        return api.get(`profile/${username}/stats`).then(response => {
            return response
        })
    },
    getFriends:(username) => {
        return api.get(`social/friends/${username}`).then(response => {
            return response
        })
    },
    getSubs:(username) => {
        return api.get(`social/subs/${username}`).then(response => {
            return response
        })
    },
    follow:(id) => {
        return api.put(`social/follow/${id}`,{}).then(response => {
            return response
        })
    },
    unFollow:(id) => {
        return api.delete(`social/follow/${id}`).then(response => {
            return response
        })
    },
    unSub:(id) => {
        return api.delete(`social/unsub/${id}`).then(response => {
            return response
        })
    },
    updateStatus:(status) => {
        return api.put(`profile/userstatus`,{user_quote:status}).then(response => {
            return response
        })
    },
    updateNote:(id,note) => {
        return api.put(`profile/${id}/note`,{note:note}).then(response => {
            return response
        })
    },
    abuseUser:(id,abuse) => {
        return api.post(`social/report/user/${id}`,{abuse:abuse}).then(response => {
            return response
        })
    },
    addBlacklist:(id) => {
        return api.put(`social/bl/${id}`,{}).then(response => {
            return response
        })
    },
    removeBlacklist:(id) => {
        return api.delete(`social/bl/${id}`).then(response => {
            return response
        })
    },
    getRandomQuestion:() => {
        return api.get(`q/`).then(response => {
            return response
        })
    },
    skipRandomQuestion:(id) => {
        return api.put(`q/`,{"q":id}).then(response => {
            return response
        })
    },
    getQuestion:(id,page,more=null) => {
        let url
        if (page&&more) url = `q/${id}?page=${page}&more=1`;
        else if(page) url = `q/${id}?page=${page}`;
        else url = `q/${id}`;
        return api.get(url).then(response => {
            return response
        })
    },

    getTags:() => {
        return api.get(`tags`).then(response => {
            return response
        })
    },

    skip:(obj) => {
        return api.put(`q/skip`,obj).then(response => {
            return response
        })
    },

    sendQuest:(data) => {
        return api.post(`q/`,data).then(response => {
            return response
        })
    },

    getAnswer:(id,page,more=null,reverse=null) => {
        let url;
        if (page&&more&&reverse) url = `q/a/${id}?page=${page}&more=1&reverse=1`;
        else if (page&&more) url = `q/a/${id}?page=${page}&more=1`;
        else if(page) url = `q/a/${id}?page=${page}`;
        else url = `q/a/${id}`;
        return api.get(url).then(response => {
            return response
        })
    },

    sendAnswer:(id,answer) => {
        return api.post(`q/${id}`,answer).then(response => {
            return response
        })
    },

    sendComment:(id,comment,skip) => {
        comment.append('skip',skip)
        return api.post(`q/a/${id}`,comment).then(response => {
            return response
        })
    },

    getAnswerNotify:() => {
        return api.get(`q/notify`).then(response => {
            return response
        })
    },
    getCommentNotify:() => {
        return api.get(`q/a/notify`).then(response => {
            return response
        })
    },
    getAnswerStream:() => {
        return api.get(`q/stream`).then(response => {
            return response
        })
    },

    likeQuestion:(id) => {
        return api.put(`q/${id}/like`,{}).then(response => {
            return response
        })
    },
    likeAnswer:(id) => {
        return api.put(`q/a/${id}/like`,{}).then(response => {
            return response
        })
    },
    likeComment:(id) => {
        return api.put(`q/a/c/${id}/like`,{}).then(response => {
            return response
        })
    },
    removePost:(id,type) => {
        let url
        if(type==='question') url = `q/${id}/delete`
        else if (type==='answer') url = `q/a/${id}/delete`
        else if (type==='comment') url = `q/a/c/${id}/delete`
        return api.put(url,{}).then(response => {
            return response
        })
    },
    subPost:(id,type) => {
        let url
        if (type==='question'||type==='questions') url = `/q/${id}/sub`
        else if(type==='answer'||type==='answers') url = `/q/a/${id}/sub`
        return api.put(url,{}).then(response => {
            return response
        })
    },
    reportPost:(id,type) =>{
        const url = {
            question:`q/${id}/report`,
            answer:`q/a/${id}/report`,
            comment:`q/a/c/${id}/report`,
            blogPost:`blog/post/${id}/report`,
            blogComment:`blog/comment/${id}/report`
        }
        return api.post(url[type],{})
    },
    addGreylist:(id) => {
        return api.put(`social/gl/${id}`,{}).then(response => {
            return response
        })
    },
    removeGreylist:(id) => {
        return api.delete(`social/gl/${id}`).then(response => {
            return response
        })
    },
    getUserStats:(username,type,my,page) => {
        let url
        if(type==='quests') url = `q/user${username}`
        else if(type==='answers') {
            if (my) url = `q/sub`
            else url = `q/a/user${username}`
        }
        else if(type==='comments') {
            if (my) url=`q/a/sub`
            else url = `q/a/c/user${username}`
        }
        else if (type==='stream')
            url = 'q/stream/sub'
        else if (type==='top')
            url = 'q/top'
        if (page) url = `${url}?page=${page}`
        return api.get(url).then(response => {
            return response
        })
    },
    getRootNote:() => {
        return api.get(`notes/root?mini=1`).then(response => {
            return response
        })
    },
    getStreamNote:() => {
        return api.get(`notes/stream?mini=1`).then(response => {
            return response
        })
    },
    getAnswerNote:() => {
        return api.get(`notes/answers?mini=1`).then(response => {
            return response
        })
    },
    getCommentNote:() => {
        return api.get(`notes/comments?mini=1`).then(response => {
            return response
        })
    },
    getOther:(page=null) => {
        let url = `notes/`
        if(page) url = `notes/?page=${page}`
        return api.get(url).then(response => {
            return response
        })
    },
    removeNotif:(obj) => {
        return api.put(`notes/`,obj).then(response => {
            return response
        })
    },
    editQuest:(id,data) => {
        return api.put(`q/${id}`,data).then(response => {
            return response
        })
    },
    editAnswer:(id,data) => {
        return api.put(`q/a/${id}`,data).then(response => {
            return response
        })
    },
    editComment:(id,data) => {
        return api.put(`q/a/c/${id}`,data).then(response => {
            return response
        })
    },
    getPms:() => {
        return api.get(`/social/chat`).then(response => {
            return response
        })
    },
    getPm:(username,page=null) => {
        let url
        if(username) url = `/social/chat/${username}`
        else url = `/notes/chat`
        if (page) url = `${url}?page=${page}`
        return api.get(url).then(response => {
            return response
        })
    },
    postPm:(username,pm) => {
        return api.post(`/social/chat/${username}`,pm).then(response => {
            return response
        })
    },
    getAnket:() => {
        return api.get(`edit/`).then(response => {
            return response
        })
    },
    updateAnket:(form) => {
        return apiFiles.put(`edit/anket?convert=1`,form).then(response => {
            return response
        })
    },
    updateInterface:(form) => {
        return api.put(`edit/interface`,form).then(response => {
            return response
        })
    },
    updateNotifications:(form) => {
        return api.put(`edit/notifications`,form).then(response => {
            return response
        })
    },
    deleteProfile:() => {
        return api.delete(`/edit/`).then(response => {
            return response
        })
    },
    restoreProfile:() => {
        return api.put(`/edit/`).then(response => {
            return response
        })
    },
    updateIgnoredTags:(tags) => {
        return api.put(`edit/ignoredtags`,{tags:tags}).then(response => {
            return response
        })
    },
    getfinanceLogs:(page=1) => {
        return api.get(`logs?page=${page}`).then(response => {
            return response
        })
    },
    getDaily:() => {
        return api.get(`daily/stats`).then(response => {
            return response
        })
    },
    getRate:(page=null)=>{
        return api.get(`rate${page?`?page=${page}`:''}`).then(response => {
            return response
        })
    },
    claimDaily:(id) => {
        return api.put(`daily/stats`,{id}).then(response => {
            return response
        })
    },
    buyRate:(count)=>{
        return api.post(`/shop`,{type:"rate",count}).then(response => {
            return response
        })
    },
    getLeaders:(daily=false)=>{
        let url = '/profile/leaders'
        if(daily) url = '/profile/daily_leaders'
        return api.get(url).then(response => {
            return response
        })
    },
    getStats:() => {
        return api.get(`q/stats`).then(response => {
            return response
        })
    },
    addBroadcast:(message) => {
        return api.post(`broadcast/message`,{message:message}).then(response => {
            return response
        })
    },
    getBroadcastDetails:() => {
        return api.get(`broadcast/message`).then(response => {
            return response
        })
    },
    deleteImage:(type,id)=>{
        return api.delete(`/q/${type}/${id}/image`).then(response => {
            return response
        })
    },
    checkUpload:(type,id)=>{
        return api.get(`/q/${type}/${id}/image`).then(response => {
            return response
        })
    },
    getGifts:(category) => {
        let url = 'gifts/showcase'
        if(category) url = `${url}?cat=${category}`
        return api.get(url).then(response => {
            return response
        })
    },
    getUserGifts:(username,page=1) => {
        return api.get(`gifts/list/${username}?page=${page}`).then(response => {
            return response
        })
    },
    userGift:(username,gift) => {
        return api.post(`gifts/list/${username}`,gift).then(response => {
            return response
        })
    },
    acceptGift:(id) => {
        return api.put(`gifts/${id}`,{}).then(response => {
            return response
        })
    },
    deleteGift:(id) => {
        return api.delete(`gifts/${id}`).then(response => {
            return response
        })
    },
    getStatic:(page) => {
        return api.get(`static?mini=1&page=${page}`).then(response => {
            return response
        })
    },
    changeAppSettings:(obj) => {
        return api.post(`profile/mini_settings`,obj).then(response => {
            return response
        })
    },
    setEmail:(obj) => {
        return api.post(`profile/email`,obj).then(response => {
            return response
        })
    },
    getPrefs:(obj) => {
        return api.post(`profile/mini_prefs`,obj).then(response => {
            return response
        })
    },
    updatePrefs:(obj) => {
        return api.put(`profile/mini_prefs`,obj).then(response => {
            return response
        })
    },
}

export default otvechaiMiniApi